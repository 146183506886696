<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card :loading="loading">
            <v-card-title>
                {{ longId }}
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div class="hljs mx-10 py-10" v-html="renderedNotebook" v-katex:auto></div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { nb } from '@determined-ai/notebook-render'
import hljs from 'highlight.js'
import { marked } from 'marked'

import 'katex/dist/katex.min.css'

const tokenizer = {
    // Override escaping to exclude \{...\} blocks
    escape(src) {
        const match = src.match(/\\{|\\}/)
        if (match?.index === 0) {
            return {
                type: 'text',
                raw: match[0],
                text: match[0]
            }
        }

        // return false to use original escape tokenizer
        return false
    },
    paragraph(src) {
        const firstMath = src.split('$$\n\n')[0]
        if (src !== firstMath) {
            return {
                type: 'text',
                raw: src,
                text: src
            }
        }
        return false
    }
}

marked.use({
    tokenizer
})

nb.highlighter = (text, pre, code, lang) => {
    const language = lang || 'text'
    const hl = hljs.highlight(text, { language })
    pre.className = 'language-' + language
    if (typeof code !== 'undefined') {
        code.className = 'language-' + language
    }
    return hl.value
}

export default {
    props: ['longId', 'notebookContent', 'loading'],
    data() {
        return {
            dialog: false
        }
    },
    computed: {
        renderedNotebook() {
            if (!this.notebookContent) return
            const parsedNotebook = nb.parse(this.notebookContent)
            return parsedNotebook.render(this.notebookContent).outerHTML
        }
    }
}
</script>

<style scoped lang="scss">
.hljs {
    font-size: 16px;
    background-color: white;
    ::v-deep {
        h1 {
            margin-bottom: 0.5em;
        }
        .katex {
            font-size: 1.2em;
        }
        .katex-display {
            padding: 1em 0;
        }
        .nb-code-cell {
            padding: 0;
            code[data-language] {
                background-color: transparent;
                padding: 0;
            }
            pre {
                margin: 0;
                padding: 0.5em 1em;
            }
            pre[class*='language-'] {
                overflow: auto;
            }
            .nb-input {
                margin: 1em 0;
                padding: 0;
                background: #eee;
                border: 1px solid #bdbdbd;
            }
            .nb-output {
                margin-top: 0.5em;
                margin-bottom: 1em;
                padding-left: 0.5em;
                border-left: 2px solid #bdbdbd;
            }
        }
        .mord .accent {
            background-color: transparent !important;
        }
    }
}
</style>
